import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';
const Brand = () => {
    return (
        <div className="section">
            <video src="/test.mp4" muted autoPlay loop />
            <div className="container">
                <div className='overlay'/>
                <SectionTitle 
                    subtitle=""
                    title="Build Solution That Are Smart </br> Not Just Modern"
                    description="When you work with us, you ensure the exellence of the product with the utility of the solution. Make your next moblie app development project
                    innovative as well as useful for your users. Let's kickstart your business journey to innovation."
                    textAlignment="text-on-video"
                    textColor=""
                />
            </div>
            <ul className="list-unstyled shape-group-10">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/line-9.png"} alt="Circle" /></li>
            </ul>
        </div>
    )
}

export default Brand ;