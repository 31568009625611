import React from 'react';
import Form from '../contact/Form';


const About = () => {
    return (
        <section className="section section-padding-equal bg-color-light" id='contact-us'>
            <div className="container">
                <div className="contact-form-box">
                    <h3 className="title">Let's Chat</h3>
                    <p>Send us a message and we'll get in touch with you shortly to explore how we can work together.</p>
                    <Form />
                </div>
            </div>
            <ul className="shape-group-6 list-unstyled">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-7.png"} alt="Bubble" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="line" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/line-5.png"} alt="line" /></li>
            </ul>
        </section>
    )
}

export default About;