import React from 'react';
import Footer from '../common/footer/Footer';
import Header from '../common/header/Header';
import SEO from '../common/SEO';
import About from '../component/about/About';
import Banner from '../component/banner/Banner';
import Brand from '../component/brand/Brand';
import Plans from '../component/plans/Plans';
import Project from '../component/project/Project';
import Services from '../component/service/Services';
import Testimonial from '../component/testimonial/Testimonial';
import SectionTitle from '../elements/section-title/SectionTitle';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import CaseStudies from '../component/caseStudies/CaseStudies';


const HomePage = () => {

    return (
        <>
            <SEO title="Cipher"/>
            <ColorSwitcher />
            <main className="main-wrapper">
                <Header />
                <Banner />
                <div className="section section-padding-1 bg-color-light" id='services'>
                    <div className="container">
                        <SectionTitle 
                            subtitle=""
                            title="What can we help you with ?"
                            description="From indeation to execution and beyond, we empower businesses and deliver advanced digital
                            solutions using next-gen technologies to help them quickly achieve their business goals."
                            textAlignment="heading-left"
                            textColor=""
                        />
                        <div className='row'>
                            <Services colSize="col-xl-6 col-md-6" serviceStyle="" itemShow="4" />
                        </div>
                    </div>
                    <ul className="list-unstyled shape-group-10">
                        <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/line-9.png"} alt="Circle" /></li>
                        <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-42.png"} alt="Circle" /></li>
                        <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-43.png"} alt="Circle" /></li>
                    </ul>
                </div>
                <Project />
                {/* <Testimonial /> */}
                <Brand />
                <div className="section  section-padding" id='plans'>
                    <div className="container ">
                        <SectionTitle 
                            subtitle=""
                            title="Choose From Our Top Engagement Models"
                            description="We have designed customized plans that are best suited for your project
                            specifications and business goals."
                            textAlignment=""
                            textColor=""
                        />
                        <Plans/>
                    </div>
                    <ul className="list-unstyled shape-group-3">
                        <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/line-1.png"} alt="shape" /></li>
                        <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-4.png"} alt="shape" /></li>
                    </ul>
                </div>
                {/* <CaseStudies/> */}
                <About />
            <Footer parentClass="" />
            </main>
        </>
    )
}

export default HomePage;

