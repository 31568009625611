import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../common/header/Header';
import SEO from '../common/SEO';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Carousel from 'react-bootstrap/Carousel';
import About from '../component/about/About';
import Footer from '../common/footer/Footer';
// import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
// import 'pure-react-carousel/dist/react-carousel.es.css';
import { SimpleSlider } from './compon/SimpleSlider';


const Saahel = () => {
    return (
        <>
        <SEO title="Saahel" />
        <ColorSwitcher />
            <main className="main-wrapper">
                <Header />
                <div className="">
                    <div className="">
                        <div className='container'>
                            <div className="info row section-padding ">
                                <div className='col-lg-6 col-md-12 mt-5'>
                                    <div className='row mb-5'>
                                        <div className='col-lg-2'><img src={process.env.PUBLIC_URL + "/saahel.png"} alt="logo" /></div>
                                        <div className='col-lg-10'>
                                            <h4 className='mb-1'>
                                                Saahel App
                                                <img className='rounded ms-2 ' src={process.env.PUBLIC_URL + "/flag.png"} alt='flag' width={30}/>
                                            </h4>
                                            <a href='https://www.saahel.ly'  target='_blank'>saahel.ly</a>
                                        </div>
                                    </div>
                                    <p>
                                        An app for a snow resort in Colorado. The intention of the app is to remove cash circulation from the resort in the future and replace it with a cryptocurrency called SNOWBITES
                                    </p>
                                    <div className='section-padding-3 '>
                                        <a href='https://apps.apple.com/us/app/%D8%B3%D8%A7%D9%87%D9%84/id6444165732' target='_blank'>
                                            <img src={process.env.PUBLIC_URL + "/appstore.svg"} alt="logo" />
                                        </a>
                                        <a href='https://play.google.com/store/apps/details?id=ly.saahel.app&hl=en&gl=US' target='_blank'>
                                            <img src={process.env.PUBLIC_URL + "/googleplay.svg"}  className='ms-3' alt="logo" />
                                        </a>
                                    </div>
                                </div>
                            <div className='col-lg-6 col-md-12 mokup'>
                                <img src={process.env.PUBLIC_URL + "/SaahelMockup.png"} alt="logo" />
                            </div>
                            </div>
                        </div>
                        <div className='section bg-color-light section-padding-3 '>
                            <div className='container'>
                                <div className='responsible '>
                                    <h4>We were responsible for:</h4>
                                    <div className='p-2 flex-fill' >
                                        <span>Digital Product Design Workshops</span>
                                        <span>Digital Product Design Workshops</span>
                                        <span>Digital Product Design Workshops</span>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <div className='challenge section-padding-3'>
                                            <h4>Challenge</h4>
                                            <p>
                                            The challenge was to develop a custom iOS keyboard as similar to the native keyboard as possible.
                                            The keyboard is the part of the application that is collects data from the user based on the text typed. The user is a patient undergoing psychotherapy. The application, through an algorithm monitors the mental state of the patient and connects him to the doctor.
                                            </p>
                                        </div>
                                        <div className='challenge '>
                                            <h4>Solution</h4>
                                            <p>
                                            The challenge was to develop a custom iOS keyboard as similar to the native keyboard as possible.
                                            The keyboard is the part of the application that is collects data from the 
                                            </p>
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                    <div className='screen-slider '>
                                        <div className='app-screen text-center'>
                                            <h4>App Screens</h4>
                                            <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                                                <div className="carousel-inner">
                                                    <div className="carousel-item active">
                                                        <img
                                                        className="d-block"
                                                        src="/images/appScreens/9.png"
                                                        alt="First slide"
                                                        width='80%'
                                                        />
                                                    </div>
                                                    <div className="carousel-item">
                                                        <img
                                                        className="d-block"
                                                        src="/images/appScreens/8.png"
                                                        alt="Second slide"
                                                        />
                                                    </div>
                                                    <div className="carousel-item">
                                                        <img
                                                        className="d-block"
                                                        src="/images/appScreens/10.png"
                                                        alt="Third slide"
                                                        />
                                                    </div>
                                                    <div className="carousel-item">
                                                        <img
                                                        className="d-block"
                                                        src="/images/appScreens/11.png"
                                                        alt="Third slide"
                                                        />
                                                    </div>
                                                </div>
                                                <button className="carousel-control-prev test" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                                                    <span className="carousel-control-prev-icon-test" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Previous</span>
                                                </button>
                                                <button className="carousel-control-next test" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                                                    <span className="carousel-control-next-icon-test" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Next</span>
                                                </button>
                                            </div>
                                            {/* <Carousel>
                                                <Carousel.Item>
                                                    <img
                                                    className="d-block"
                                                    src="/images/appScreens/9.png"
                                                    alt="First slide"
                                                    width='80%'
                                                    />
                                                </Carousel.Item>
                                                <Carousel.Item>
                                                    <img
                                                    className="d-block"
                                                    src="/images/appScreens/8.png"
                                                    alt="Second slide"
                                                    />
                                                </Carousel.Item>
                                                <Carousel.Item>
                                                    <img
                                                    className="d-block"
                                                    src="/images/appScreens/10.png"
                                                    alt="Third slide"
                                                    />
                                                </Carousel.Item>
                                                <Carousel.Item>
                                                    <img
                                                    className="d-block"
                                                    src="/images/appScreens/11.png"
                                                    alt="Third slide"
                                                    />
                                                </Carousel.Item>
                                            </Carousel> */}
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <ul className="list-unstyled shape-group-10">
                                {/* <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/line-9.png"} alt="Circle" /></li> */}
                                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-42.png"} alt="Circle" /></li>
                                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-43.png"} alt="Circle" /></li>
                            </ul>
                        </div>
                        <div className='section section-padding-4 app-features'>
                            <div className='cont'>
                                <div className='container'>
                                    <h4>App Features</h4>
                                </div>
                                <SimpleSlider/>
                                {/* <CarouselProvider
                                naturalSlideWidth={100}
                                naturalSlideHeight={100}
                                totalSlides={3}
                                >
                                    <Slider>
                                        <Slide index={0}>
                                            <div className='row'>
                                                <div className='col'>
                                                    <div className='feature-item'>
                                                        <h5 >Add New Item 1</h5>
                                                        <p>Save your preferences by adding them to a presets list. All setting can be stored and recalled from internal memory for quick setup.</p>
                                                    </div>
                                                </div>
                                                <div className='col'>
                                                    <div className='feature-item'>
                                                        <h5 >Add New Item 2</h5>
                                                        <p>Save your preferences by adding them to a presets list. All setting can be stored and recalled from internal memory for quick setup.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Slide>
                                        <Slide index={1}>
                                            <div className='row'>
                                                <div className='col'>
                                                    <div className='feature-item'>
                                                        <h5 >Add New Item 3</h5>
                                                        <p>Save your preferences by adding them to a presets list. All setting can be stored and recalled from internal memory for quick setup.</p>
                                                    </div>
                                                </div>
                                                <div className='col'>
                                                    <div className='feature-item'>
                                                        <h5 >Add New Item 4</h5>
                                                        <p>Save your preferences by adding them to a presets list. All setting can be stored and recalled from internal memory for quick setup.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Slide>
                                        <Slide index={2}>
                                            <div className='row'>
                                                <div className='col'>
                                                    <div className='feature-item'>
                                                        <h5 >Add New Item 5</h5>
                                                        <p>Save your preferences by adding them to a presets list. All setting can be stored and recalled from internal memory for quick setup.</p>
                                                    </div>
                                                </div>
                                                <div className='col'>
                                                    <div className='feature-item'>
                                                        <h5 >Add New Item 6</h5>
                                                        <p>Save your preferences by adding them to a presets list. All setting can be stored and recalled from internal memory for quick setup.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Slide>
                                    </Slider>
                                    <ButtonBack className='btn-back'>Back</ButtonBack>
                                    <ButtonNext className='btn-next'>Next</ButtonNext>
                                </CarouselProvider> */}


                                {/* <h4 className='section-padding-3'>App Features</h4>
                                <div className='row justify-content-around '>
                                    <div className='col-5 feature-item'>
                                        <h5 >Add New Item</h5>
                                        <p>Save your preferences by adding them to a presets list. All setting can be stored and recalled from internal memory for quick setup.</p>
                                    </div>
                                    <div className='col-5 feature-item'>
                                        <h5 >Add New Item</h5>
                                        <p>Save your preferences by adding them to a presets list. All setting can be stored and recalled from internal memory for quick setup.</p>
                                    </div>
                                    <div className='col-5 feature-item mt-6 mt-4'>
                                        <h5 >Add New Item</h5>
                                        <p>Save your preferences by adding them to a presets list. All setting can be stored and recalled from internal memory for quick setup.</p>
                                    </div>
                                    <div className='col-5 feature-item mt-6 mt-4'>
                                        <h5 >Add New Item</h5>
                                        <p>Save your preferences by adding them to a presets list. All setting can be stored and recalled from internal memory for quick setup.</p>
                                    </div>
                                </div> */}
                            </div>
                            <ul className="shape-group-6 list-unstyled">
                                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="line" /></li>
                                {/* <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/line-5.png"} alt="line" /></li> */}
                            </ul>
                        </div>
                        <div className='section bg-color-light testimoial text-center '>
                            <div className='container'>
                                <div className='sub-container'>
                                    <h5>Testimonial</h5>
                                    <div>
                                        <div>
                                            <img
                                            className="d-block"
                                            src="/images/man.png"
                                            alt="Third slide"
                                            width={96}
                                            />
                                            <span>Malik Ahsreef</span>
                                            <span className='role'>Product Owner</span>
                                            <p>
                                            Many companies claim they do Agile developmentMany companies claim they do Agile development
                                            Many companies claim they do Agile development part of 
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ul className="shape-group-6 list-unstyled">
                                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-7.png"} alt="Bubble" /></li>
                                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="line" /></li>
                                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/line-5.png"} alt="line" /></li>
                            </ul>
                        </div>
                        <Footer/>
                    </div>
                </div>
            </main>
        </>
    )
}

export default Saahel;