import React from "react";
import Slider from "react-slick";
import "./slick.css"; 
import "./slick-theme.css";

export const SimpleSlider = () => {
  
    const settings = {
      dots: false,
      infinite: false,
      speed: 800,
      slidesToShow: 2,
      slidesToScroll: 2,
      arrows: true,
      centerMode: true,
      centerPadding: 300,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: false,
            dots: false,
            centerMode: false
          }
        },
        {
          breakpoint: 880,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
            centerMode: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            centerMode: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false
          }
        }
      ]
    };
    return (
      <Slider {...settings}>
        <div className='feature-item'>
          <h5 >Add New Item 1</h5>
          <p>Save your preferences by adding them to a presets list. All setting can be stored and recalled from internal memory for quick setup.</p>
        </div>
        <div className='feature-item'>
          <h5 >Add New Item 2</h5>
          <p>Save your preferences by adding them to a presets list. All setting can be stored and recalled from internal memory for quick setup.</p>
        </div>
        <div className='feature-item'>
          <h5 >Add New Item 3</h5>
          <p>Save your preferences by adding them to a presets list. All setting can be stored and recalled from internal memory for quick setup.</p>
        </div>
        <div className='feature-item'>
          <h5 >Add New Item 4</h5>
          <p>Save your preferences by adding them to a presets list. All setting can be stored and recalled from internal memory for quick setup.</p>
        </div>
        <div className='feature-item'>
          <h5 >Add New Item 5</h5>
          <p>Save your preferences by adding them to a presets list. All setting can be stored and recalled from internal memory for quick setup.</p>
        </div>
      </Slider>
    );
  
}