import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaTwitter, FaPinterestP, FaLinkedin, FaInstagram, FaEnvelopeOpen } from "react-icons/fa";
import ServiceData from "../../data/service/Services.json";

const getServiceData = ServiceData;

const Footer = ({parentClass}) => {
    
    return (
        <footer className={`footer-area ${parentClass}`}>
            <div className="container">
                <div className="footer-top">
                </div>
                <div className="footer-main">
                    <div className="row">
                        <div>
                            <div className="row">
                                <div className='col-xl-4'>
                                    <div className="footer-widget border-end">
                                        <h6 className="widget-title">Information</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                <li className='info-li'>+218928389946</li>
                                                <li className='info-li'>3rd Ring Road, Misurata, Libya</li>
                                                <li className='info-li'>info@cipher.ly</li>
                                            </ul>
                                            <div className="footer-social-link">
                                                <ul className="list-unstyled">
                                                    <li><a target="_blank" href="https://www.facebook.com/cipher.com.ly"><FaFacebookF /></a></li>
                                                    <li><a target="_blank" href="https://www.linkedin.com/company/cipher-it/"><FaLinkedin /></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="footer-widget">
                                        <h6 className="widget-title">Services</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                {getServiceData.slice(0, 6).map((data, index) => (
                                                    <li key={index}>
                                                        <a href="#services">{data.title}</a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-2">
                                    <div className="footer-widget">
                                        <h6 className="widget-title">Resourses</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                <li><a href="#about-us">About us</a></li>
                                                {/* <li> <a href="#case-studies">Case Studies</a></li> */}
                                                <li><a href="#plans">Plans</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="footer-widget">
                                        <h6 className="widget-title">Support</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                <li><a href="#contact-us">Contact</a></li>
                                                <li> <Link to="#">Privacy Policy</Link></li>
                                                <li><Link to="#">Terms of Use</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom"> 
                    <div className="row">
                        <div className="col-md-6">
                            <div className="footer-copyright">
                                <span className="copyright-text">© {new Date().getFullYear()}. All rights reserved by <a href="https://cipher.ly/">Cipher</a>.</span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="footer-bottom-link">
                                <ul className="list-unstyled">
                                    <li><Link to="#">Privacy Policy</Link></li>
                                    <li><Link to="#">Terms of Use</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;