import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import ScrollToTop from './component/scrollToTop/ScrollToTop';
import 'bootstrap/dist/css/bootstrap.min.css';

import ErrorPage from './pages/404';
import Saahel from './pages/Saahel';
// import Test from './pages/Test';
// Css Import
import './assets/scss/app.scss';
import Lampa from './pages/Lampa';
import Ilap from './pages/Ilap';
import EnoughTea from './pages/EenoughTea';
import Sales from './pages/Sales';
import HomePage from './pages/HomePage';


const App = () => {
  return (
    <Router>
		<ScrollToTop>
			<Routes>
				<Route path="/" element={<HomePage />}/>
				<Route path="/404" element={<ErrorPage />}/>
				<Route path="/saahel" element={<Saahel />}/>
				<Route path="/lampa" element={<Lampa />}/>
				<Route path="/ilap" element={<Ilap />}/>
				<Route path="/enoughtea" element={<EnoughTea />}/>
				<Route path="/sales" element={<Sales />}/>
				{/* <Route path={process.env.PUBLIC_URL + "/test"} element={<Test />}/> */}
			</Routes>
		</ScrollToTop>
    </Router>
  )
}

export default App;
