import React from 'react';
import PlansData from "../../data/plans/PlansData.json";

const getPlansData = PlansData;

const Plans = () => {
    return (
        <>
            <div className="row">
                {getPlansData.map((data, index) => (
                    <div className="col-lg-4" key={index}>
                        <div className="plans-table plans-borderd">
                            <div className="plans-header">
                                <h3 className="title">{data.title}</h3>
                                <span className="subtitle">{data.subtitle}</span>
                            </div>
                            <div className="plans-body">
                                <ul className="list-unstyled">
                                    {data.details.map((data, index) => (
                                        <li key={index}>{data}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

export default Plans;