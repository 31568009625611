import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import ServiceDate from '../../data/service/Services.json';

const AllData = ServiceDate;

const Services = ({colSize, serviceStyle, itemShow, marginTop}) => {

	const topMargin = (index) => {
		if (marginTop === "yes") {
			if (index === 0) {
				return "mt--200 mt_md--0";
			} else if (index === 1) {
				return "mt--100 mt_md--0";
			}else {
				return "mt--0";
			}
		}else {
			return "";
		}
	}
    return (
		<>
			{AllData.slice(0, itemShow).map((data, index) => (
				<div className={`${colSize} ${topMargin(index)}`} key={index} >
					<div className={`services-grid ${serviceStyle}`}>
						<div className={`thumbnail thumbnail${data.id}`}>
							<img src={process.env.PUBLIC_URL + data.image} className="img" alt="icon" />
						</div>
						<div className="content">
							<h5 className="title"> 
								{data.title}
							</h5>
							<p>{data.description}</p>
							<div className={`sub-title sub-title${data.id}`}>
								<AnimationOnScroll animateIn="fadeInUp" animateOnce={true} delay={100}>
									<span>{data.span1}</span>
									<span>{data.span2}</span>
									<span>{data.span3}</span>
								</AnimationOnScroll>
							</div>
						</div>
					</div>
			 	</div>
			))}
		</>
    )
}

export default Services;