import React from 'react';
import Header from '../common/header/Header';
import SEO from '../common/SEO';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import Carousel from 'react-bootstrap/Carousel';
import Footer from '../common/footer/Footer';
import { SimpleSlider } from './compon/SimpleSlider';


const Ilap = () => {

    return (
        <>
        <SEO title="Ilap" />
        <ColorSwitcher />
            <main className="main-wrapper">
                <Header />
                <div className="">
                    <div className="">
                        <div className='container'>
                            <div className="info row section-padding ">
                                <div className='col-lg-6 col-md-12 mt-5'>
                                    <div className='row mb-5'>
                                        <div className='col-lg-2'><img src={process.env.PUBLIC_URL + "/images/ilap.png"} alt="logo" /></div>
                                        <div className='col-lg-10'>
                                            <h4 className='mb-1'>
                                                Ilap App
                                                <img className='rounded ms-2 ' src={process.env.PUBLIC_URL + "/flag.png"} alt='flag' width={30}/>
                                            </h4>
                                            <a href='https://www.saahel.ly'  target='_blank'>Ilap.ly</a>
                                        </div>
                                    </div>
                                    <p>
                                        An app for a snow resort in Colorado. The intention of the app is to remove cash circulation from the resort in the future and replace it with a cryptocurrency called SNOWBITES
                                    </p>
                                    
                                </div>
                            <div className='col-lg-6 col-md-12 mokup'>
                                <img src={process.env.PUBLIC_URL + "/SaahelMockup.png"} alt="logo" />
                            </div>
                            </div>
                        </div>
                        <div className='section bg-color-light section-padding-3 '>
                            <div className='container'>
                                <div className='responsible '>
                                    <h4>We were responsible for:</h4>
                                    <div className='p-2 flex-fill' >
                                        <span>Digital Product Design Workshops</span>
                                        <span>Digital Product Design Workshops</span>
                                        <span>Digital Product Design Workshops</span>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <div className='challenge section-padding-3'>
                                            <h4>Challenge</h4>
                                            <p>
                                            The challenge was to develop a custom iOS keyboard as similar to the native keyboard as possible.
                                            The keyboard is the part of the application that is collects data from the user based on the text typed. The user is a patient undergoing psychotherapy. The application, through an algorithm monitors the mental state of the patient and connects him to the doctor.
                                            </p>
                                        </div>
                                        <div className='challenge '>
                                            <h4>Solution</h4>
                                            <p>
                                            The challenge was to develop a custom iOS keyboard as similar to the native keyboard as possible.
                                            The keyboard is the part of the application that is collects data from the 
                                            </p>
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                    <div className='screen-slider '>
                                        <div className='app-screen text-center'>
                                            <h4>App Screens</h4>
                                            <Carousel>
                                                <Carousel.Item>
                                                    <img
                                                    className="d-block"
                                                    src="SaahelMockup.png"
                                                    alt="First slide"
                                                    />
                                                </Carousel.Item>
                                                <Carousel.Item>
                                                    <img
                                                    className="d-block"
                                                    src="SaahelMockup.png"
                                                    alt="Second slide"
                                                    />
                                                </Carousel.Item>
                                                <Carousel.Item>
                                                    <img
                                                    className="d-block"
                                                    src="SaahelMockup.png"
                                                    alt="Third slide"
                                                    />
                                                </Carousel.Item>
                                            </Carousel>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <ul className="list-unstyled shape-group-10">
                                {/* <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/line-9.png"} alt="Circle" /></li> */}
                                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-42.png"} alt="Circle" /></li>
                                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-43.png"} alt="Circle" /></li>
                            </ul>
                        </div>
                        <div className='section section-padding-4 app-features ilap'>
                            <div className='cont'>
                                <div className='container'>
                                    <h4>App Features</h4>
                                </div>
                                <SimpleSlider/>
                            </div>
                            <ul className="shape-group-6 list-unstyled">
                                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="line" /></li>
                                {/* <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/line-5.png"} alt="line" /></li> */}
                            </ul>
                        </div>
                        <div className='section bg-color-light testimoial text-center '>
                            <div className='container'>
                                <div className='sub-container'>
                                    <h5>Testimonial</h5>
                                    <div>
                                        <div>
                                            <img
                                            className="d-block"
                                            src="/images/man.png"
                                            alt="Third slide"
                                            width={96}
                                            />
                                            <span>Cipher</span>
                                            <span className='role'>Product Owner</span>
                                            <p>
                                            Many companies claim they do Agile developmentMany companies claim they do Agile development
                                            Many companies claim they do Agile development part of 
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ul className="shape-group-6 list-unstyled">
                                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-7.png"} alt="Bubble" /></li>
                                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="line" /></li>
                                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/line-5.png"} alt="line" /></li>
                            </ul>
                        </div>
                        <Footer/>
                    </div>
                </div>
            </main>
        </>
    )
}

export default Ilap;