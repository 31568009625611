import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';
import Carousel from 'react-bootstrap/Carousel';

const Project = () => {

	let width = window.innerWidth;
    return (
		<>
			<div className={`section section-padding-1`} id='about-us'>
                <div className="container">
                <SectionTitle 
					subtitle=""
					title="How we build app ?"
					textAlignment="center"
					textColor=""
					/>
					{
						width > '1200' ?

							<Carousel>
								<Carousel.Item>
									<div className='row'>
										<div className="col-lg-2  test-class ">
											<div>
												<h2>01</h2>
											</div>
										</div>
										<div className="col-lg-10 col-sm-12">
											<div className="project-table project-borderd">
												<div className="project-header">
													<h3 className="title">Defining your Business Strategy</h3>
												</div>
												<div className="project-body">
													<ul className="list-unstyled">
														<li>Together, we'll clarify your business Strategy and set up a briefing to find the right direction in developing tour web or mobile app.</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
									<div className='row'>
										<div className="col-lg-10 col-sm-12">
											<div className="project-table project-borderd">
												<div className="project-header">
													<h3 className="title">Product Discovery Workshop and App Prototyping</h3>
												</div>
												<div className="project-body">
													<ul className="list-unstyled">
														<li>We'll decide on the app functionalities and the optimal technologies to use based on your business goals and user's needs.</li>
													</ul>
												</div>
											</div>
										</div>
										<div className="col-lg-2 test-class">
											<div>
												<h2>02</h2>
											</div>
										</div>
									</div>
								</Carousel.Item>
								<Carousel.Item>
									<div className='row'>
										<div className="col-lg-2 test-class">
											<div>
												<h2>03</h2>
											</div>
										</div>
										<div className="col-lg-10">
											<div className="project-table project-borderd">
												<div className="project-header">
													<h3 className="title">UX/UI App Deesign</h3>
												</div>
												<div className="project-body">
													<ul className="list-unstyled">
														<li>Your digital product must have a strong personality that captures the user's attention. We deliver clean,eye-catching designs alwaysin tune with the latest trends while bringing something more. </li>
													</ul>
												</div>
											</div>
										</div>
									</div>
									<div className='row'>
										<div className="col-lg-10">
											<div className="project-table project-borderd">
												<div className="project-header">
													<h3 className="title">App Development</h3>
												</div>
												<div className="project-body">
													<ul className="list-unstyled">
														<li>Time to actually build the product , writescalable, integrate all services, test it and make sure this version of your app is ready for the infinit scalse.</li>
													</ul>
												</div>
											</div>
										</div>
										<div className="col-lg-2 test-class">
											<div>
												<h2>04</h2>
											</div>
										</div>
									</div>
								</Carousel.Item>
								<Carousel.Item>
						<div className='row'>
							<div className="col-lg-2 test-class">
								<div>
									<h2>05</h2>
								</div>
							</div>
							<div className="col-lg-10">
								<div className="project-table project-borderd">
									<div className="project-header">
										<h3 className="title">Quality Assurance (QA) and App Testing</h3>
									</div>
									<div className="project-body">
										<ul className="list-unstyled">
											<li>Your product wikk have a QA specialist assigned (whom, by the way, is twice as nit picking as your users) to ensure the best version of your bug free app.</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div className='row'>
							<div className="col-lg-10">
								<div className="project-table project-borderd">
									<div className="project-header">
										<h3 className="title">App Launch</h3>
									</div>
									<div className="project-body">
										<ul className="list-unstyled">
											<li>Ready to hustle the app market ? We are prepared tolaunch your digital production the App Store, Google Playor deploy it to your web server
												. Alternatively, we can distribute the app directly to your in-house or enter price stake holders via other channels.</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="col-lg-2 test-class">
								<div>
									<h2>06</h2>
								</div>
							</div>
						</div>
								</Carousel.Item>
								<Carousel.Item>
									<div className='row'>
										<div className="col-12 test-class">
											<div>
												<h2>07</h2>
											</div>
										</div>
										<div className="col-12">
											<div className="project-table project-borderd">
												<div className="project-header">
													<h3 className="title">App Maintenance & Support</h3>
												</div>
												<div className="project-body">
													<ul className="list-unstyled">
														<li>The app is launched, users are signing up and every thing works smoothly under our 24/7 surveillance, based on solid monitoring & tranking.
															it's time for you to sit back and think about developing new features, as our long-lasting partner ship continues.</li>
													</ul>
												</div>
											</div>
										</div>
										
									</div>
							</Carousel.Item>
						</Carousel>
						: 
						<Carousel>
							<Carousel.Item>
								<div className='row'>
									<div className="col-12 test-class ">
										<div>
											<h2>01</h2>
										</div>
									</div>
									<div className="col-12">
										<div className="project-table project-borderd">
											<div className="project-header">
												<h3 className="title">Defining your Business Strategy</h3>
											</div>
											<div className="project-body">
												<ul className="list-unstyled">
													<li>Together, we'll clarify your business Strategy and set up a briefing to find the right direction in developing tour web or mobile app.</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</Carousel.Item>
							<Carousel.Item>
								<div className='row'>
									<div className="col-12 test-class ">
										<div>
											<h2>02</h2>
										</div>
									</div>
									<div className="col-12">
										<div className="project-table project-borderd">
											<div className="project-header">
												<h3 className="title">Product Discovery Workshop and App Prototyping</h3>
											</div>
											<div className="project-body">
												<ul className="list-unstyled">
													<li>We'll decide on the app functionalities and the optimal technologies to use based on your business goals and user's needs.</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</Carousel.Item>
							<Carousel.Item>
								<div className='row'>
									<div className="col-12 test-class ">
										<div>
											<h2>03</h2>
										</div>
									</div>
									<div className="col-12">
										<div className="project-table project-borderd">
											<div className="project-header">
												<h3 className="title">UX/UI App Deesign</h3>
											</div>
											<div className="project-body">
												<ul className="list-unstyled">
													<li>Your digital product must have a strong personality that captures the user's attention. We deliver clean,eye-catching designs alwaysin tune with the latest trends while bringing something more.</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</Carousel.Item>
							<Carousel.Item>
								<div className='row'>
									<div className="col-12 test-class ">
										<div>
											<h2>04</h2>
										</div>
									</div>
									<div className="col-12">
										<div className="project-table project-borderd">
											<div className="project-header">
												<h3 className="title">App Development</h3>
											</div>
											<div className="project-body">
												<ul className="list-unstyled">
													<li>Time to actually build the product , writescalable, integrate all services, test it and make sure this version of your app is ready for the infinit scalse.</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</Carousel.Item>
							<Carousel.Item>
								<div className='row'>
									<div className="col-12 test-class ">
										<div>
											<h2>05</h2>
										</div>
									</div>
									<div className="col-12">
										<div className="project-table project-borderd">
											<div className="project-header">
												<h3 className="title">Quality Assurance (QA) and App Testing</h3>
											</div>
											<div className="project-body">
												<ul className="list-unstyled">
													<li>Your product wikk have a QA specialist assigned (whom, by the way, is twice as nit picking as your users) to ensure the best version of your bug free app.</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</Carousel.Item>
							<Carousel.Item>
								<div className='row'>
									<div className="col-12 test-class ">
										<div>
											<h2>06</h2>
										</div>
									</div>
									<div className="col-12">
										<div className="project-table project-borderd">
											<div className="project-header">
												<h3 className="title">App Launch</h3>
											</div>
											<div className="project-body">
												<ul className="list-unstyled">
													<li>Ready to hustle the app market ? We are prepared tolaunch your digital production the App Store, Google Playor deploy it to your web server
														. Alternatively, we can distribute the app directly to your in-house or enter price stake holders via other channels.</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</Carousel.Item>
							<Carousel.Item>
								<div className='row'>
									<div className="col-12 test-class">
										<div>
											<h2>07</h2>
										</div>
									</div>
									<div className="col-12">
										<div className="project-table project-borderd">
											<div className="project-header">
												<h3 className="title">App Maintenance & Support</h3>
											</div>
											<div className="project-body">
												<ul className="list-unstyled">
													<li>The app is launched, users are signing up and every thing works smoothly under our 24/7 surveillance, based on solid monitoring & tranking.
														it's time for you to sit back and think about developing new features, as our long-lasting partner ship continues.</li>
												</ul>
											</div>
										</div>
									</div>

								</div>
							</Carousel.Item>
						</Carousel>
					}
				
                </div>
            </div>
		</>
    )
}

export default Project;