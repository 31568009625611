import React, {useRef, useState} from 'react';
import emailjs from '@emailjs/browser';
import Alert from 'react-bootstrap/Alert';
import ReCAPTCHA from "react-google-recaptcha";
const Passed = () => {
    return (
        <Alert variant="success" className="success-msg">
            Your Message has been successfully sent.
        </Alert>
    )
}

const Failed = () => {
    return (
        <Alert variant="danger" className="danger-msg">
            Failed to send your message.
        </Alert>
    )
}


const FormOne = () => {

    const form = useRef();
    const recaptchaRef = React.useRef();
    const [ result, showresult ] = useState(0);
    const [ token, setToken ] = useState("");


    const sendEmail = async (e) => {
        e.preventDefault();

        //const token = await recaptchaRef.current.executeAsync();
        //setToken(token)
        emailjs.sendForm('cipher_it_landingpage', 'cipher-landingpage-templ', form.current, 'rB48ms1slw48lNEd2')
          .then((result) => {
              console.log(result.text);
              form.current.reset();
            showresult(1);
          }, (error) => {
              showresult(2)
          });
          
      };

        setTimeout(() => {
            showresult(0);
        }, 5000);

        
    return (
        <form ref={form} onSubmit={sendEmail} className="axil-contact-form">
           <div className='row'>
                <div className='col-lg-6 col-sm-12'>
                    <div className="form-group ">
                        <label>Name</label>
                        <input type="text" className="form-control" name="contact_name" placeholder="John Smith" required />
                    </div>
                    <div className="form-group">
                        <label>Email</label>
                        <input type="email" className="form-control" name="contact_email" placeholder="example@mail.com" required />
                    </div>
                    <div className="form-group mb--40">
                        <label>Phone</label>
                        <input type="tel" className="form-control" name="contact_phone" placeholder="+123456789" required />
                    </div>
                    
                </div>
                <div className='col-lg-6 col-sm-12'>
                    <div className="form-group mb--40">
                        <label>Company</label>
                        <input type="text" className="form-control" name="contact_company" placeholder="Cipher" required />
                        
                    </div>
                    <div className="form-group mb--40">
                        <label>Message</label>
                        <textarea type="text" className="form-control text-area" name="contact_message" placeholder=""/>
                    </div>
                    {/* //<input type="hidden" name="g-recaptcha-response" placeholder="Cipher" value={token} required /> */}
                    <div className="form-group">
                        {result == 0 ? null : result == 1 ? <Passed /> : <Failed />}
                    </div>
                </div>
                <ReCAPTCHA
           size=""
            ref={recaptchaRef}
              sitekey="6LdN9Z4hAAAAAB0TzUW3DNGcpJm7a5hKMpI-RNe2" required
             />
                <div className="form-group btn-submit">
                    <button type="submit" className="axil-btn btn-fill-primary  btn-primary " name="submit-btn">Send</button>
                </div>
                
           </div>
           
        </form>
    )
}

export default FormOne;