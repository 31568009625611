import React from 'react';

const Nav = () => {
    return (
        <nav className="mainmenu-nav">
            <ul className="mainmenu">
                <li className="menu-item-has-children">
                    <a href="/#services">Services</a>
                </li>
                <li className="menu-item-has-children">
                    <a href="/#plans">Plans</a>
                </li>
                {/* <li className="menu-item-has-children">
                    <a href="/#case-studies">Case Studies</a>
                </li> */}
                <li className="menu-item-has-children">
                    <a href="/#about-us">About us</a>
                </li>
                <li className="menu-item-has-children">
                    <a href="/#contact-us">Contact us</a>
                </li>
            </ul>
        </nav>
    )
}

export default Nav;